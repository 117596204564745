<template>
  <div class="projects-wrapper">
    <ProjectCard v-for="(project, index) in projects" :key="index" :project="project"/>
  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
export default {
  name: 'Projects',
  components: {
    ProjectCard
  },
  data () {
    return {
      projects: [
        {
          img: '/images/mockbackend-mockup.png',
          alt: 'MockBackend',
          title: 'MockBackend',
          text: ['Mock RESTful backend CLI & Desktop App', 'Lets you create custom paths with dummy data', 'Features a proxy to catch and redirect domains to the mock server'],
          github: {
            enabled: false,
            repo: 'https://github.com/MonoDepth/MockBackend-Core'
          }
        },
        {
          img: '/images/monovtt-logo.svg',
          alt: 'MonoVTT',
          title: 'MonoVTT',
          text: ['Online Multiplayer Tabletop Engine', 'Made with DND in mind but works for any kind of tabletop game']
        },
        {
          img: '/images/c-sharp-logo.svg',
          alt: 'MonoDB',
          title: 'MonoDB',
          text: ['Database interface library to load data to classes during runtime from MySQL, SQLite and PostgreSQL databases'],
          github: {
            enabled: false,
            repo: 'https://github.com/MonoDepth/MonoDB'
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.projects-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
</style>