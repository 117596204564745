<template>
  <span class="card">
    <img class="showcase-image" :src="project.img" :alt="project.alt">
    <span class="main-content">
      <div class="text">
        <p class="title">{{project.title}}</p>
        <p v-for="(text, index) in project.text" :key="index">
          {{text}}
        </p>
      </div>
      <a v-if="project.github" 
        class="github-link" 
        :class="{'disabled-link': !project.github.enabled}" 
        :href="project.github.enabled ? project.github.repo : '#'" 
        target="_blank"         
      > 
        <img src="/images/GitHub-Mark-64px.png" alt="Github">
        <p>{{project.github.enabled ? 'Github Repository' : 'Github Repository (Coming soon!)'}}</p>
      </a>
    </span>
  </span>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    project: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
    display: grid;
    grid-template-columns: 33% 1fr;
    width: 90%;   
    max-width: 800px;
    height: 300px;
    padding: 20px;
    align-items: center;
    margin-bottom: 80px;
    background: var(--project-bg);
    color: var(--project-font-color);

    .showcase-image { 
      max-height: 80%;
      width: 100%;
    }

    .title {
      font-weight: bold;
    }

    .main-content {
      display: grid;
      grid-template-rows: 1fr 30px;
      height: 100%;

      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      
      .github-link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: var(--link-color);
        img {
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }        
      }

      .disabled-link {
          color: var(--disabled-link-color);
          cursor: not-allowed;
          pointer-events: none;
      }
    }
  }
</style>
